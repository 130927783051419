import React from 'react';
import styles from './button.module.scss';
import clsx from 'clsx';

export function SubmitButton({ label, className, loading = false, ...other }) {
  return (
    <button
      {...other}
      className={clsx(styles.button, styles.submitButton, loading && styles.loading, className)}
      type="submit"
      value={label}
    >
      {loading && <div className={styles.loader} />}
      {label}
    </button>
  );
}
