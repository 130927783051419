import { useState } from 'react';

/**
 * Fetch the remote API and process the result.
 * @param api API URL to fetch
 * @returns {Promise<{showConfirmation: boolean, setServerError: React.Dispatch<React.SetStateAction<boolean>>, fetch: doFetch, serverError: boolean, setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>}>}
 */
export function useFormFetch(api = '/api/kontakt') {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [serverError, setServerError] = useState(false);

  const onSubmit = async (data, { setErrors }) => {
    setServerError(false);
    const response = await fetch(api, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      setShowConfirmation(true);
    } else if (response.status === 409) {
      const jsonData = await response.json();
      const errors = jsonData.errors;
      if (Array.isArray(errors)) {
        const e = {};
        errors.forEach((errorResult) => {
          e[errorResult.field] = errorResult.msg;
        });
        setErrors(e);
      } else {
        setServerError(true);
      }
    } else {
      setServerError(true);
    }
  };

  return {
    onSubmit,
    showConfirmation,
    setShowConfirmation,
    serverError,
    setServerError,
  };
}
