export const formValidator = (values) => {
  const errors = {};
  const maxNameLength = 80;

  if (!values.name) {
    errors.name = 'Ups, dieses Angabe brauchen wir auf jeden Fall.';
  } else if (values.name.length > maxNameLength) {
    errors.name = `Ups, hängt bei Ihnen eine der Tasten? Sie haben zu langen Text hier angegeben! Bitte maximal ${maxNameLength} Zeichen angeben.`;
  }

  if (!values.email) {
    errors.email = 'Ups, dieses Angabe brauchen wir auf jeden Fall.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Geben Sie bitte eine gültige E-Mail-Adresse an.';
  }

  if (!values.message) {
    errors.message = 'Ups, dieses Angabe brauchen wir auf jeden Fall.';
  }

  return errors;
};
