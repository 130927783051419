import React from 'react';

import { Button } from '../button/button';
import IconError from '../../images/icons/error.svg';

import styles from './form-response.module.scss';

export const FormServerError = ({ serverError, setServerError }) => {
  return (
    <>
      {serverError && (
        <div>
          <img src={IconError} alt="Error icon" />
          <div>
            Es ist ein unbekannter Fehler aufgetreten. Ihre Anfrage konnte nicht übermittelt werden. Bitte versuchen Sie
            es später noch ein mal.
          </div>
          <br />
          <div>
            <Button
              className={styles.btn}
              variant="primary"
              name="close-server-error"
              aria-label="Fehlermeldung Schließen"
              onClick={() => {
                setServerError(false);
              }}
            >
              Formular einblenden
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
