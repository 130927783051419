import React from 'react';
import clsx from 'clsx';
import styles from './forms.module.scss';

export function TextField({ id = '', label = '', name, type = 'text', required = false, error, ...other }) {
  const htmlId = id || `text-field-${name}`;

  return (
    <div className={styles.textFieldWrapper}>
      {label && (
        <label htmlFor={htmlId} className={clsx(styles.textFieldLabel, error && styles.error)}>
          {label}
        </label>
      )}
      <input {...other} type={type} name={name} id={htmlId} className={clsx(styles.textField, error && styles.error)} />
      {error && <p className={styles.textFieldErrorMsg}>{error}</p>}
    </div>
  );
}

export function TextArea({ id = '', label, name, type = 'text', required = false, error, ...other }) {
  const htmlId = id || `text-area-${name}`;

  return (
    <div className={styles.textFieldWrapper}>
      {label && (
        <label htmlFor={htmlId} className={clsx(styles.textFieldLabel, error && styles.error)}>
          {label}
        </label>
      )}
      <textarea {...other} name={name} id={htmlId} className={clsx(styles.textField, error && styles.error)} />
      {error && <p className={styles.textFieldErrorMsg}>{error}</p>}
    </div>
  );
}
