import React from 'react';
import { useSpring, animated as a } from 'react-spring';
import { FormConfirmation } from './form-confirmation';
import { FormServerError } from './form-server-error';

import styles from './forms.module.scss';

export const Form = ({
  children,
  showConfirmation,
  setShowConfirmation,
  serverError,
  setServerError,
  resetForm,
  ...otherProps
}) => {
  const confirmationSpring = useSpring({
    transform: `translateY(${showConfirmation ? 100 * -1 : 0}%)`,
  });

  const serverErrorSpring = useSpring({
    transform: `translateY(${serverError ? 100 * -1 : 0}%)`,
  });

  return (
    <form className={styles.form} {...otherProps}>
      {children}
      <a.div className={styles.confirmationContainer} style={confirmationSpring}>
        <FormConfirmation setShowConfirmation={setShowConfirmation} resetForm={resetForm} />
      </a.div>
      <a.div className={styles.serverErrorMsg} style={serverErrorSpring}>
        <FormServerError serverError={serverError} setServerError={setServerError} />
      </a.div>
    </form>
  );
};
