import React from 'react';
import { useFormik } from 'formik';

import { createPhoneLink } from '../../../lib/helpers';
import FORM_TYPES from '../../../../api/_form-types-handlers/FORM_TYPES';
import { Form } from '../../forms/form';
import { formValidator } from '../../forms/validator';
import { useFormFetch } from '../../forms/form-fetch';
import { TextField, TextArea } from '../../forms/text-field';
import { SubmitButton } from '../../button/submit';

import styles from './kontakt.module.scss';

export function Kontakt() {
  const { onSubmit, showConfirmation, setShowConfirmation, serverError, setServerError } = useFormFetch();

  const { handleChange, errors, touched, handleBlur, isSubmitting, handleSubmit, values, resetForm } = useFormik({
    initialValues: {
      formType: FORM_TYPES.KONTAKT,
      name: '',
      email: '',
      message: '',
    },
    validate: formValidator,
    onSubmit,
  });

  const contactDetails = [
    {
      type: 'phone',
      name: 'Telefon',
      text: '+49 40 882159 40',
      isLink: true,
    },
    {
      type: 'email',
      name: 'E-Mail',
      text: 'kontakt@evelan.de',
      isLink: true,
    },
    {
      type: 'text',
      name: 'WhatsApp',
      text: '+49 172 97 53 655',
      isLink: false,
    },
    {
      type: 'text',
      name: 'City',
      text: 'Hamburg, Germany',
      isLink: false,
    },
  ];

  return (
    <div className={styles.container}>
      <div>
        <h2>lassen sie uns in</h2>
        <h2 className={styles.headingStroke}>verbindung bleiben</h2>
        <div className={styles.detailsWrapper}>
          {contactDetails.map(({ type, name, text, isLink }, i) => (
            <div key={i} className={styles.item}>
              <p>{name}</p>
              {!!isLink ? (
                <a href={type === 'phone' ? createPhoneLink(text) : `mailto:${text}`}>{text}</a>
              ) : (
                <h3>{text}</h3>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.formWrapper}>
        <h2 className={styles.headingPrimary}>senden sie mir eine</h2>
        <h2 className={styles.headingStroke}>nachricht</h2>
        <Form
          onSubmit={handleSubmit}
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          serverError={serverError}
          setServerError={setServerError}
          resetForm={resetForm}
        >
          <div className={styles.primaryInfoWrapper}>
            <TextField
              label="Name"
              id="kontakt-name"
              placeholder="Ihr Name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && errors.name}
              required={true}
              value={values.name}
            />
            <TextField
              label="E-Mail"
              id="kontakt-email"
              placeholder="Ihre E-Mail Adresse "
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && errors.email}
              required={true}
              value={values.email}
            />
          </div>
          <TextArea
            label="Nachricht"
            id="kontakt-message"
            placeholder="Ihre Nachricht"
            name="message"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.message && errors.message}
            required={true}
            value={values.message}
          />
          <SubmitButton
            label="Nachricht senden"
            className={styles.submitBtn}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </Form>
      </div>
    </div>
  );
}
