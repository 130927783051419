import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import { Button } from '../button/button';

import styles from './form-response.module.scss';

export const FormConfirmation = ({ setShowConfirmation, resetForm }) => {
  const { portrait } = useStaticQuery(graphql`
    query {
      portrait: file(name: { eq: "Andreas_Portrait" }) {
        childImageSharp {
          fluid(
            quality: 100
            maxWidth: 2000
            srcSetBreakpoints: [340, 768, 2000]
            traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#0e1f38", color: "#0ec2a6" }
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
      <h2>klasse aktion!</h2>
      <p>Vielen Dank für Ihre Nachricht. Ich werde mich bald wieder bei Ihnen melden!</p>
      <p>Ich wünsche ihnen einen angenehmen Tag!</p>
      <div className={styles.portraitWrapper}>
        <Img
          className={styles.portraitImage}
          fluid={portrait.childImageSharp.fluid}
          loading="auto"
          alt="Andreas Straub Portrait"
        />
        <p>Andreas Straub</p>
      </div>
      <Button
        className={styles.btn}
        onClick={() => {
          setShowConfirmation(false);
          resetForm();
        }}
      >
        Zurück zum Formular
      </Button>
    </div>
  );
};
